import React from "react";
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from '../assets/style/images/common/logo.jpg';

function LoginPage() {
  return (
    <div className="-loginPage">
      <div className="loginContainer">
        <div className="loginWrap">
          <div className="loginWrap__header">
            <img className="logo" src={logo} alt="logo" />
          </div>
          <div className="loginWrap__content">
            <Form>
              <Form.Group className="mb-3" controlId="email">
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="Password">
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
            </Form>
            <div className="buttonWrap">
              <Button className="btnPrimary btn-lg" type="submit">
                Submit
              </Button>
            </div>
            <div className="d-block text-center">
              <Link to={`/`} className="txt-primary">Register for a new FREE account</Link>
            </div>
          </div>
        </div>
        <div className="cprFooter">
          <span className="cprFooter__content">2017-2020 © <Link to={`/`} className="txt-primary">Med2Lab Inc</Link>. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;