import React from "react";
import { Container, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

function HomePage() {
  const columns = [
    {
      dataField: "id",
      text: "",
      headerClasses: "headTbl text-center font-weight-500",
      hidden: true
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "headTbl text-center font-weight-500"
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "headTbl text-center font-weight-500"
    },
    {
      dataField: "Diagonis",
      text: "Diagonis",
      headerClasses: "headTbl text-center font-weight-500"
    },
    {
      dataField: "DueNotice",
      text: "Due/Notice",
      headerClasses: "headTbl text-center font-weight-500"
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Button className="py-1 px-3 d-flex align-items-center mr-2" variant="outline-success">
              <span className="icn-view"></span>
            </Button>
            <Button className="py-1 px-3 d-flex align-items-center mr-2" variant="outline-warning">
              <span className="icn-view"></span>
            </Button>
            <Button className="py-1 px-3 d-flex align-items-center" variant="outline-danger">
              <span className="icn-delete"></span>
            </Button>
          </div>
        );
      },
      classes: "align-middle",
      headerClasses: "headTbl text-center font-weight-500"
    },
  ];

  const data = [
    {id: 1, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 2, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 3, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 4, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 5, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 6, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 7, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 8, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 9, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 10, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 11, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 12, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 13, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 14, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 15, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 16, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 17, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 18, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 19, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 20, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 21, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""},
    {id: 22, FirstName: "Jeffrey", LastName: "Cox", Diagonis: "Neuroblastoma", DueNotice: ""}
  ]
  return (
    <div className="mainPage">
      <Container fluid={true}>
        <div className="contentHead d-flex align-items-center">
          <h1 className="headingPage">Main Dashboard</h1>
          <Button className="btnPrimary btn-sm ml-auto" type="submit">
          <span class="icn-sync mr-2"></span>Update System
          </Button>
        </div>
        <div className="mainCt py-4">
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory()}
            striped
            condensed
            wrapperClasses="table-responsive"
            bordered={false}
          />
        </div>
      </Container>
    </div>
  );
}

export default HomePage;