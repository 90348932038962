import React, { Component } from 'react';

export default class FooterComponent extends Component {
  render () {
    //get year for copyright
    const getCurrentYear = () => {
      return new Date().getFullYear();
    };
    return (
      <footer className="border-top">
        <div className="container">
          <p className="text-center mb-0">
            Copyright by Med2lab {getCurrentYear()} | All rights reserved
          </p>
        </div>
      </footer>
    )
  }
}
