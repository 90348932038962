import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConnectedSwitch from './ConnectedSwitch';
import { MasterLayout } from '../components/layouts';

const RenderRoutes = ({ routes }) => {
  
  if (!routes) {
    return null
  }

  return (
    <ConnectedSwitch>
    {routes.map((route, i) => (
      <Route
      key={i}
      path={route.path}
      exact={route.exact}
      render={(props) => (
          <MasterLayout 
            showLayout={route.layout}
            classPage={route.className}
          >
            <route.component
              {...props}
              route={route}
              routes={route.routes}
            />
          </MasterLayout>
        )}
      />
    ))}
    </ConnectedSwitch>
  )
}

RenderRoutes.propTypes = {
  routes: PropTypes.array.isRequired
}

const mapStateToProps = state => ({})

export default withRouter(connect(mapStateToProps, null)(RenderRoutes))