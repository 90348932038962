import React from "react";

//components
import { HeaderComponent, FooterComponent } from "../layouts";

const MasterLayout = (props) => {
  const classPage = props && props.classPage
  const showLayout = props && props.showLayout

  return (
    <div id="outer-container">
      <div id="page-wrap" className={`wrapper ${classPage}`}>
        {showLayout && <HeaderComponent {...props} />}
          <main className="main-content">{props.children}</main>
        {showLayout && <FooterComponent {...props} />}
      </div>
    </div>
  );
};

export default MasterLayout;