import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store';
import Root from './Root';
import { routes } from './routes';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/main.css';

const store = configureStore()
const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, store)

ReactDOM.render((
  <Root
    store={store}
    routes={routes}
    history={history}
  />
), document.getElementById('root'))
serviceWorker.unregister();