import { createStore, applyMiddleware, compose } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers';
import { apiClients, apiMiddlewareConfig } from '../middleware';
import logger from 'redux-logger';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const initialState = {}
const middleware = [thunk]

const configureStore = preloadedState => {
  if (process.env.NODE_ENV === 'production') {
    const store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        ...middleware,
        multiClientMiddleware(apiClients, apiMiddlewareConfig)
      )
    )

    if (module.hot) {
      module.hot.accept('../reducers', () => {
        const nextReducer = require('../reducers')
        store.replaceReducer(nextReducer)
      })
    }
    
    return store
  } else {
    const store = createStore(
      rootReducer,
      initialState,
      composeEnhancers(
        applyMiddleware(
          ...middleware,
          logger,
          multiClientMiddleware(apiClients, apiMiddlewareConfig)
        )
      )
    )

    if (module.hot) {
      module.hot.accept('../reducers', () => {
        const nextReducer = require('../reducers')
        store.replaceReducer(nextReducer)
      })
    }

    return store
  }
}

export default configureStore
