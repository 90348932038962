import React, { useRef } from "react";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import logo from "../../assets/style/images/common/logo.jpg";
import { useDetectOutsideClick } from "./useDetectOutsideClick";

const HeaderComponent = (props) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const navDropdownTitle = (
    <React.Fragment>
      <span className="icn-cogwheel mr-2"></span> Settings
    </React.Fragment>
  );
  return (
    <header>
      <div className="headerTop bg-white">
        <Container fluid={true}>
          <Row className="d-flex align-items-center">
            <Col sm={8}>
              <img
                alt="logo"
                src={logo}
                width="160"
                className="d-inline-block align-top"
              />
            </Col>
            <Col sm={4}>
              <div className="proFileCt">
                <button onClick={onClick} className="proFileCt__trigger">
                  <span>
                    Hi,<span className="text-danger">Bao</span>
                  </span>
                  <div
                    className="imgUser"
                    style={{
                      backgroundImage: `url('https://ca.slack-edge.com/T09BLGA75-U09BM2T7X-d39ab599645b-512')`,
                    }}
                  ></div>
                </button>
                <nav
                  ref={dropdownRef}
                  className={`menuProfile ${isActive ? "active" : "inactive"}`}
                >
                  <div className="userLg">
                    <div className="userLg__left">
                      <div
                        className="imgUser"
                        style={{
                          backgroundImage: `url('https://ca.slack-edge.com/T09BLGA75-U09BM2T7X-d39ab599645b-512')`,
                        }}
                      ></div>
                    </div>
                    <div className="userLg__right">
                      <p className="txt-primary font-weight-500">Bao Truong</p>
                      <p className="text-muted">bao@med2lab.com</p>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <a className="d-flex align-items-center" href="#"><span className="icn-profile mr-2 font-30"></span>My Profile</a>
                    </li>
                    <li className="text-left">
                      <Button
                        className="btnPrimary-outline"
                        variant="outline-primary"
                      >
                        Logout
                      </Button>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        className="background-primary"
        collapseOnSelect
      >
        <Container fluid={true}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#/">
                <span className="icn-user mr-2"></span>Patitents
              </Nav.Link>
              <Nav.Link href="#/">
                <span className="icn-calendar mr-2"></span>Calendar
              </Nav.Link>
              <Nav.Link href="#/">
                <span className="icn-chat mr-2"></span>Chat
              </Nav.Link>
              <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">
                <NavDropdown.Item href="#/">Menu Item 01</NavDropdown.Item>
                <NavDropdown.Item href="#/">Menu Item 02</NavDropdown.Item>
                <NavDropdown.Item href="#/">Menu Item 03</NavDropdown.Item>
                <NavDropdown.Item href="#/">Menu Item 04</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ml-auto">
              <form className="search-form">
                <input type="search" placeholder="Search" className="search-input" />
                <button type="submit" className="search-button">
                  <span className="icn-zoom"></span>
                </button>
              </form>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default HeaderComponent;
