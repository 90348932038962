import {
  HomePage,
  LoginPage
} from "../pages";

const routes = [
  {
    path: "/",
    exact: true,
    component: HomePage,
    className: '-homePage',
    layout: true
  },
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    className: '-loginPage',
    layout: false
  }
];

export default routes;